<template>
  <div style="margin-top: 100px">
    <div class="opps">
      <div class="opps-header">
        <div class="opps-reminder">
          Ficha digital. No es necesario imprimir.
        </div>
        <div class="opps-info">
          <div class="opps-logo">
            <img src="../assets/conekta_white.svg" alt="CONEKTA" />
          </div>
          <div class="opps-title">
            <h2>Pago con efectivo</h2>
          </div>
          <div class="opps-brand">
            <img src="../assets/oxxo_brand.png" alt="OXXO" />
          </div>
          <div class="opps-ammount">
            <h3 class="primary--text">Monto a pagar</h3>
            <h2 class="turquoise--text">$ {{paymentReference.amount}}<sup>MXN</sup></h2>
            <p class="error--text">
              OXXO cobrará una comisión adicional al momento de realizar el
              pago.
            </p>
          </div>
        </div>
        <div class="opps-reference">
          <h3 class="primary--text">Status: <span v-if="!paymentReference.datePaid" class="error--text">Pendiente</span><span v-else class="success-text">Pagado</span></h3>  
          <h3 class="primary--text">Referencia</h3>
          <h1 class="primary--text">{{paymentReference.reference}}</h1>
          <h1>
            <img
              id="barcode"
              src="https://api.qrserver.com/v1/create-qr-code/?data=HelloWorld&amp;size=100x100"
              alt=""
              title="QR CODE"
              width="100"
              height="100"
            />
          </h1>
          <h1 class="primary--text">Productos</h1>
            <v-row align="start" justify="start">
              <v-col cols="12" align="start" justify="start" v-if="productsOrder">
                <h1 class="primary--text" v-for="course,index in productsOrder.courses" :key="index">{{index+1}} - {{course.name}}</h1>
              </v-col>
            </v-row>
            <v-row align="start" justify="start" v-if="productsOrder">
              <v-col cols="12" align="start" justify="start">
                <h1 class="primary--text" v-for="certification,index in productsOrder.certifications" :key="index">{{index+1}} - {{certification.nombre}}</h1>
              </v-col>
            </v-row>
        </div>
      </div>
      <div class="opps-instructions">
        <h3>Instrucciones</h3>
        <ol>
          <li>
            Acude a la tienda OXXO más cercana.
            <a
              href="https://www.google.com.mx/maps/search/oxxo/"
              target="_blank"
              >Encuéntrala aquí</a
            >.
          </li>
          <li>
            Indica en caja que quieres realizar un pago de servicio<strong
            ></strong>.
          </li>
          <li>
            Dicta al cajero el número de referencia en esta ficha para que
            tecleé directamete en la pantalla de venta.
          </li>
          <li>Realiza el pago correspondiente con dinero en efectivo.</li>
          <li>
            Al confirmar tu pago, el cajero te entregará un comprobante impreso.
            <strong
              >En el podrás verificar que se haya realizado
              correctamente.</strong
            >
            Conserva este comprobante de pago.
          </li>
        </ol>
        <div class="opps-footnote">
          Al completar estos pasos recibirás un correo de
          <strong>Nombre del negocio</strong> confirmando tu pago.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      productsOrder: null,
    }
  },
  mounted(){
    const nric = this.paymentReference.reference
    const url =
        "https://api.qrserver.com/v1/create-qr-code/?data=" +
        nric +
        "&amp;size=50x50";
    const bc = document.getElementById('barcode')
    bc.src = url
    this.GetProductOrders(this.paymentReference)
  },
  methods:{
    GetProductOrders(data){
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.accessToken,
      };
      this.axios.get(`/user/history/${data.orderId}`, { headers: headers })
      .then(res=>{
        this.productsOrder = res.data
      })
      .catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
    }
  },
  computed: {
    ...mapState(['paymentReference']),
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
body {
  font-size: 14px;
}


h3 {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.opps {
  width: 496px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 45px;
  margin: 40px auto;
  overflow: hidden;
  border: 1px solid #b0afb5;
  font-family: "Open Sans", sans-serif;
  color: #4f5365;
}

.opps-reminder {
  position: relative;
  top: -1px;
  padding: 9px 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  background: #000000;
}

.opps-info {
  margin-top: 2px;
  position: relative;
}

.opps-info:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.opps-logo {
  width: 45%;
  float: left;
  color: #ffffff;
  background: #171d4d;
}

.opps-logo img {
  max-width: 180px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.opps-title {
  width: 55%;
  float: right;
  float: left;
  background: #171d4d;
}

.opps-title h2 {
  margin-top: 26px;
  margin-bottom: 18px;
  font-size: 16px;
  color: #ffffff;
  align: center;
}
.opps-brand {
  width: 50%;
  float: left;
  margin-top: 20px;
}

.opps-brand img {
  max-width: 300px;
  margin-top: -20px;
  padding: 50px;
}

.opps-ammount {
  width: 55%;
  float: right;
  margin-top: 20px;
}

.opps-ammount h2 {
  font-size: 36px;
  color: #000000;
  line-height: 24px;
  margin-bottom: 15px;
}

.opps-ammount h2 sup {
  font-size: 16px;
  position: relative;
  top: -2px;
}

.opps-ammount p {
  font-size: 10px;
  line-height: 14px;
}

.opps-reference {
  margin-top: 14px;
}

h1 {
  font-size: 27px;
  color: #000000;
  text-align: center;
  margin-top: -1px;
  padding: 6px 0 7px;
  border: 1px solid #b0afb5;
  border-radius: 4px;
  background: #f8f9fa;
}

.opps-instructions {
  margin: 32px -45px 0;
  padding: 32px 45px 45px;
  border-top: 1px solid #b0afb5;
  background: #f8f9fa;
}

ol {
  margin: 17px 0 0 16px;
}

li + li {
  margin-top: 10px;
  color: #000000;
}

a {
  color: #1155cc;
}

.opps-footnote {
  margin-top: 22px;
  padding: 22px 20 24px;
  color: #108f30;
  text-align: center;
  border: 1px solid #108f30;
  border-radius: 4px;
  background: #ffffff;
}
</style>
